export const timeline = [
	{
		id: "epoqueContemporaine",
		name: "epoqueContemporaineFull",
		end: new Date().getFullYear(),
		start: 1789,
		color: "#ac3470",
		topMsg: {
			color: "#ac3470",
			year: new Date().getFullYear(),
		},
		content: [
			{
				type: "blank",
			},
		],
		bottomMsg: null,
	},
	{
		id: "periodeModerne",
		name: "periodeModerneFull",
		end: 1789,
		start: 1492,
		color: "#d988b1",
		// topMsg: {
		// 	color: "#d988b1",
		// 	year: "label1789,
		// 	desc:"blank", // "periodeModerne",
		// },
		content: [
			{
				type: "card",
				desc: "blank", //"periodeModerneDesc",
			},
		],
		bottomMsg: null,
	},
	{
		id: "moyenAge",
		name: "moyenAgeFull",
		end: 1492,
		start: 476,
		color: "#bcc06c",
		topMsg: {
			color: "#bcc06c",
			year: "label1492",
			desc: "blank", //"americaDiscover",
		},
		content: [
			{
				type: "card",
				desc: "blank",//"classicalMiddleAgesExpl",
			},
		],
		bottomMsg: null,
	},
	{
		id: "antiquite",
		name: "antiquiteFull",
		end: 476,
		start: -3500,
		color: "#99de72",
		topMsg: null,
		offset: 126,
		topMsg: {
			color: "#99de72",
			year: "label1492",
			//desc: "americaDiscover",
		},
		content: [
			{
				type: "card",
				desc: "blank",//"antiquityDuration",
				scrollArrows: {
					expl: true,
					top: "antiquity",
					bottom: "neolithicInside"
				}
			}
		],
		bottomMsg: null,
	},
	{
		id: "neolithique",
		name: "neolithiqueFull",
		end: -2100,
		start: -6500,
		color: "#5cb6e0",
		topMsg: null,
		offset: 126,
		topMsg: {
			color: "#5cb6e0",
			year: "label1492",
			desc: "blank", //"americaDiscover",
		},
		content: [
			{
				type: "card",
				desc: "blank", //"antiquityDuration",
				scrollArrows: {
					expl: true,
					top: "antiquity",
					bottom: "neolithicInside"
				}
			}
		],
		bottomMsg: null,
	},
	{
		id: "mesolithique",
		name: "mesolithiqueFull",
		end: -6000,
		start: -9600,
		color: "#6a5dc1",
		topMsg: null,
		offset: 126,
		topMsg: {
			color: "#6a5dc1",
			year: "label1492",
			desc: "blank", //"americaDiscover",
		},
		content: [
			{
				type: "card",
				desc: "blank",//"antiquityDuration",
				scrollArrows: {
					expl: true,
					top: "antiquity",
					bottom: "neolithicInside"
				}
			}
		],
		bottomMsg: null,
	},
	{
		id: "paleolitiqueSup",
		name: "paleolitiqueSupFull",
		end: -10000,
		start: -40000,
		color: "#9d5dc1",
		topMsg: {
			color: "#9d5dc1",
			year: "labelb10000",
			desc: "blank", //"endPeriod",
		},
		content: [
			{
				type: "card",
				desc: "blank",//"paleolithicDuration",
				scrollArrows: {
					expl: false,
					top: "neolithicInside",
					bottom: "magdalenianInside"
				}
			}
		],
		bottomMsg: {
			color: "#E0A381",
			year: "labelb34000",
			desc: "startPeriod",
		},
	},









	// {
	// 	id: "antiquity",
	// 	name: "timelineAntiquity",
	// 	end: -2100,
	// 	start: -6500,
	// 	color: "#9D7599",
	// 	topMsg: null,
	// 	offset: 126,
	// 	content: [
	// 		{
	// 			type: "card",
	// 			desc: "antiquityDuration",
	// 			scrollArrows: {
	// 				expl: true,
	// 				top: "antiquity",
	// 				bottom: "neolithicInside"
	// 			}
	// 		}
	// 	],
	// 	bottomMsg: null,
	// },
	// {
	// 	id: "antiquity",
	// 	name: "timelineAntiquity",
	// 	end: -6000,
	// 	start: -9600,
	// 	color: "#9D7599",
	// 	topMsg: null,
	// 	offset: 126,
	// 	content: [
	// 		{
	// 			type: "card",
	// 			desc: "antiquityDuration",
	// 			scrollArrows: {
	// 				expl: true,
	// 				top: "antiquity",
	// 				bottom: "neolithicInside"
	// 			}
	// 		}
	// 	],
	// 	bottomMsg: null,
	// },
	// {
	// 	id: "paleolithic",
	// 	name: "timelinePaleolithic",
	// 	end: -10000,
	// 	start: -40000,
	// 	color: "#E0A381",
	// 	topMsg: {
	// 		color: "#E0A381",
	// 		year: "labelb10000,
	// 		desc: "endPeriod",
	// 	},
	// 	content: [
	// 		{
	// 			type: "card",
	// 			desc: "paleolithicDuration",
	// 			scrollArrows: {
	// 				expl: false,
	// 				top: "neolithicInside",
	// 				bottom: "magdalenianInside"
	// 			}
	// 		}
	// 	],
	// 	bottomMsg: {
	// 		color: "#E0A381",
	// 		year: "labelb34000,
	// 		desc: "startPeriod",
	// 	},
	// },
];

export const subtimeline = [
	/* *************************************** */
	/* 1789 à nos jours | Epoque contemporaine */
	/* *************************************** */

	{
		id: "y2024",
		name: "epoqueContemporaine",
		end: 2024,
		offsetTitle: 1,
		offset: 20,
		height: 680,
		start: 2024,
		color: "#ac3470",
		topMsg: {
			color: "#ac3470",
			year: "label2024",
			desc: "epoqueContemporaine",
		},
		content: [
			{
				type: "card",
				desc: "y2024",
				scrollArrows: {
					expl: true,
					top: "y2024",
					bottom: "y1985y1986"
				}
			},
		],
		displayBorder: false
	},
	{
		id: "y1985y1986",
		name: "y1985y1986",
		end: 1986,
		offset: 20,
		start: 1985,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1986",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1985y1986",
			},
		],
		displayBorder: false
	},
	{
		id: "y1974y1976",
		name: "y1974y1976",
		end: 1976,
		offset: 20,
		start: 1974,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1976",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1974y1976",
			},
		],
	},
	{
		id: "y1962",
		name: "y1962",
		end: 1962,
		offset: 20,
		start: 1962,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1962",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1962",
			},
		],
	},
	{
		id: "y1956",
		name: "y1956",
		end: 1956,
		offset: 20,
		start: 1956,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1956",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1956",
			},
		],
	},
	{
		id: "y1948",
		name: "y1948",
		end: 1948,
		offset: 20,
		height: 840,
		start: 1948,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1948",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1948",
				scrollArrows: {
					expl: false,
					top: "y1956",
					bottom: "y1900y1902"
				}
			},
		],
	},
	{
		id: "y1900y1902",
		name: "y1900y1902",
		end: 1902,
		offset: 20,
		start: 1900,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1902",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1900y1902",
			},
		],
	},
	{
		id: "y1872",
		name: "y1872",
		end: 1872,
		offset: 20,
		start: 1872,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1872",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1872",
				crop: 70
			},
		],
	},
	{
		id: "y1868",
		name: "y1868",
		end: 1868,
		offsetTitle: 0,
		offset: 20,
		start: 1868,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1868",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1868",
				crop: 70
			},
		],
	},
	{
		id: "y1865",
		name: "y1865",
		end: 1865,
		offsetTitle: 40,
		offset: 36,
		start: 1865,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1865",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1865",
			},
		],
	},
	{
		id: "y1864",
		name: "y1864",
		end: 1864,
		offsetTitle: 156,
		offset: 92,
		start: 1864,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1864",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1864",
			},
		],
	},
	{
		id: "y1841",
		name: "y1841",
		end: 1841,
		offset: 20,
		start: 1841,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label30011841",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1841",
			},
		],
	},
	{
		id: "y1829y1833",
		name: "y1829y1833",
		end: 1833,
		offset: 20,
		start: 1829,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1833",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1829y1833",
				crop: 70
			},
		],
	},
	{
		id: "y1830",
		name: "y1830",
		end: 1830,
		offsetTitle: 36,
		offset: 50,
		start: 1830,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label04101830",
			desc: "y1830Title",
		},
		content: [
			{
				type: "card",
				desc: "y1830",
			},
		],
	},
	{
		id: "y1815",
		name: "y1815",
		end: 1815,
		offset: 50,
		start: 1815,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label24081815",
			desc: "y1815Title",
		},
		content: [
			{
				type: "card",
				desc: "y1815",
			},
		],
	},	
	{
		id: "y1789y1791",
		name: "y1789y1791",
		end: 1791,
		offsetTitle: 502,
		offset: 268,
		start: 1789,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1791",
			desc: "y1789y1791Title",
		},
		content: [
			{
				type: "card",
				desc: "y1789y1791",
				crop: 70
			},
		],
	},

	/* ***************************** */
	/* 1492 à 1789 | Période moderne */
	/* ***************************** */
	{
		id: "y1789y1799",
		name: "y1789y1799",
		offsetTitle: 0,
		offset: 20,
		end: 1799,
		start: 1789,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label17891799",
			desc: "y1789y1799Title",
		},
		content: [
			{
				type: "card",
				desc: "y1789y1799",
				crop: 70
			},
		],
	},
	{
		id: "y1776",
		name: "y1776",
		end: 1776,
		offsetTitle: 46,
		offset: 42,
		height: 150,
		start: 1776,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1776",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1776",
			},
		],
	},
	{
		id: "y1748",
		name: "y1748",
		end: 1748,
		offset: 20,
		height: 1940,
		start: 1748,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1748",
			desc: "y1748Title",
		},
		content: [
			{
				type: "card",
				desc: "y1748",
				scrollArrows: {
					expl: false,
					top: "y1776",
					bottom: "y1568y1648"
				}
			},
		],
	},
	{
		id: "y1793march26",
		name: "y1793march26",
		end: 1793,
		offsetTitle: 100,
		offset: 82,
		start: 1793,
		height: 20,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label26031793",
			desc: "y1793march26Title",
		},
		content: [
			{
				type: "card",
				desc: "y1793march26",
				crop: 70
			},
		],
	},
	{
		id: "sXVIsXVIII",
		name: "sXVIsXVIII",
		end: 1794,
		offsetTitle: 0,
		offset: 16,
		start: 1566,
		height: 0,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1794",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "sXVIsXVIII",
				crop: 70
			},
		],
	},
	{
		id: "y1793march01",
		name: "y1793march01",
		end: 1793,
		offsetTitle: 264,
		offset: 164,
		start: 1793,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label01031793",
			desc: "y1793march01Title",
		},
		content: [
			{
				type: "card",
				desc: "y1793march01",
				crop: 70,
			},
		],
	},
	{
		id: "y1792y1793",
		name: "y1792y1793",
		end: 1793,
		offsetTitle: 434,
		offset: 232,
		start: 1792,
		height: 40,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1793",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1792y1793",
				crop: 70
			},
		],
	},
	{
		id: "y1568y1648",
		name: "y1568y1648",
		end: 1648,
		offset: 56,
		height: 1470,
		start: 1568,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1648",
			desc: "y1568y1648Title",
		},
		content: [
			{
				type: "card",
				desc: "y1568y1648",
				scrollArrows: {
					expl: false,
					top: "y1748",
					bottom: "y1566"
				}
			},
		],
		// bottomMsg: {
		// 	color: "#e395c3",
		// 	desc: "blank",
		// },
	},
	
	{
		id: "y1566",
		name: "y1566",
		end: 1566,
		offset: 20,
		height: 930,
		start: 1566,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1566",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1566",
				scrollArrows: {
					expl: false,
					top: "sXVIsXVIII",
					bottom: "y1517",
				}
			},
			
		],
	},
	{
		id: "y1517",
		name: "y1517",
		end: 1517,
		offset: 20,
		height: 450,
		start: 1517,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1517",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1517",
				scrollArrows: {
					expl: false,
					top: "y1566",
					bottom: "y1492"
				}
			},
		],
	},
	

	/* ***************************** */
	/* 476 au XVe siècle | Moyen-Âge */
	/* ***************************** */

	{
		id: "y1492",
		name: "y1492",
		end: 1492,
		offset: 20,
		height: 710,
		start: 1492,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1492",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1492",
				scrollArrows: {
					expl: false,
					top: "y1517",
					bottom: "y1454"
				}
			},
			
		],
	},
	{
		id: "y1454",
		name: "y1454",
		end: 1454,
		offset: 20,
		height: 1030,
		start: 1454,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1454",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1454",
				scrollArrows: {
					expl: false,
					top: "y1492",
					bottom: "sXIII"
				}
			},
			
		],
	},
	{
		id: "sXIII",
		name: "sXIII",
		end: 1400,
		offset: 20,
		height: 3940,
		start: 1400,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1400",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "sXIII",
				scrollArrows: {
					expl: false,
					top: "y1454",
					bottom: "sXIsXIII"
				}
			},
			
		],
	},
	{
		id: "y1000y1100",
		name: "y1000y1100",
		end: 1100,
		offset: 20,
		start: 1000,
		height: 1850,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1100",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y1000y1100",
				scrollArrows: {
					expl: false,
					top: "sXIsXIII",
					bottom: "y476sX"
				}
			},
			
		],
	},
	{
		id: "sXIsXIII",
		name: "sXIsXIII",
		end: 1200,
		offset: 20,
		height: 1780,
		start: 1000,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1200",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "sXIsXIII",
				scrollArrows: {
					expl: false,
					top: "sXIII",
					bottom: "y1000y1100"
				}
			},
			
		],
	},
	{
		id: "y476sX",
		name: "y476sX",
		end: 1000,
		offset: 20,
		height: 5420,
		start: 476,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label1000",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y476sX",
				scrollArrows: {
					expl: false,
					top: "sXIsXIII",
					bottom: "sVsVIII"
				}
			},
			
		],
	},
	{
		id: "sV",
		name: "sV",
		end: 400,
		offset: 20,
		height: 950,
		start: 400,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label400",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "sV",
				scrollArrows: {
					expl: false,
					top: "sIIIy476",
					bottom: "sIIIsIV"
				}
			},
			
		],
	},
	{
		id: "sVsVIII",
		name: "sVsVIII",
		end: 700,
		offset: 20,
		height: 4125,
		start: 400,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label700",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "sVsVIII",
				scrollArrows: {
					expl: false,
					top: "y476sX",
					bottom: "sIIIy476"
				}
			},
			
		],
	},

	/* ************************ */
	/* -3500 à +476 | Antiquité */
	/* ************************ */

	{
		id: "y303",
		name: "y303",
		end: 303,
		offset: 20,
		height: 6550,
		start: 303,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label303",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "y303",
				scrollArrows: {
					expl: false,
					top: "sIIIsIV",
					bottom: "ya750ya27"
				}
			},
			
		],
	},
	{
		id: "sIIIsIV",
		name: "sIIIsIV",
		end: 350,
		offset: 20,
		height: 750,
		start: 200,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label350",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "sIIIsIV",
				scrollArrows: {
					expl: false,
					top: "sV",
					bottom: "y303"
				}
			},
			
		],
	},
	{
		id: "sIIIy476",
		name: "sIIIy476",
		end: 476,
		offset: 20,
		height: 1340,
		start: 300,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "label476",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "sIIIy476",
				scrollArrows: {
					expl: false,
					top: "sVsVIII",
					bottom: "sV"
				}
			},
			
		],
	},
	{
		id: "ya52",
		name: "ya52",
		end: -52,
		offset: 20,
		height: 7910,
		start: -52,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb52",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya52",
				scrollArrows: {
					expl: false,
					top: "ya750ya27",
					bottom: "ya450"
				}
			},
			
		],
	},
	{
		id: "ya450",
		name: "ya450",
		end: -450,
		start: -450,
		offset: 20,
		height: 5950,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb450",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya450",
				scrollArrows: {
					expl: false,
					top: "ya52",
					bottom: "ya2300ya750"
				}
			},
			
		],
	},
	{
		id: "ya750ya27",
		name: "ya750ya27",
		end: -27,
		offset: 20,
		height: -250,
		start: -750,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb27",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya750ya27",
				scrollArrows: {
					expl: false,
					top: "y303",
					bottom: "ya52"
				}
			},
			
		],
	},
	{
		id: "ya2300ya750",
		name: "ya2300ya750",
		end: -750,
		offset: 20,
		offset: 20,
		height: 23400,
		start: -2300,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb750",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya2300ya750",
				scrollArrows: {
					expl: false,
					top: "ya450",
					bottom: "ya5500ya2000"
				}
			},
			
		],
	},

	/* *************************** */
	/* -6500 à -2100 | Néolithique */
	/* *************************** */

	{
		id: "ya4000",
		name: "ya4000",
		end: -4000,
		offset: 20,
		height: 39940,
		start: -4000,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb4000",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya4000",
				scrollArrows: {
					expl: false,
					top: "ya5500ya2000",
					bottom: "ya6000"
				}
			},
			
		],
	},
	{
		id: "ya6000",
		name: "ya6000",
		end: -6000,
		offset: 20,
		height: 71940,
		start: -6000,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb6000",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya6000",
				scrollArrows: {
					expl: false,
					top: "ya4000",
					bottom: "ya10000ya9600"
				}
			},
			
		],
	},

	/* **************************** */
	/* -9600 à -6000 | Mésolithique */
	/* **************************** */

	{
		id: "ya5500ya2000",
		name: "ya5500ya2000",
		end: -2000,
		offset: 20,
		height: 36450,
		start: -5500,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb2000",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya5500ya2000",
				scrollArrows: {
					expl: false,
					top: "ya2300ya750",
					bottom: "ya4000"
				}
			},
			
		],
	},

	/* ***************************************** */
	/* -40000 à -10000 | Paléolithique supérieur */
	/* ***************************************** */

	{
		id: "ya10000ya9600",
		name: "ya10000ya9600",
		end: -9600,
		offset: 20,
		height: 7550,
		start: -10000,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb9600",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya10000ya9600",
				scrollArrows: {
					expl: false,
					top: "ya6000",
					bottom: "ya15000ya10000"
				}
			},
			
		],
	},
	{
		id: "ya15000ya10000",
		name: "ya15000ya10000",
		end: -10000,
		offset: 20,
		height: 214940,
		start: -15000,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb10000",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya15000ya10000",
				scrollArrows: {
					expl: false,
					top: "ya10000ya9600",
					bottom: "ya21000"
				}
			},
			
		],
	},
	{
		id: "ya21000",
		name: "ya21000",
		end: -21000,
		offset: 20,
		height: 179940,
		start: -21000,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb21000",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya21000",
				scrollArrows: {
					expl: false,
					top: "ya15000ya10000",
					bottom: "ya30000"
				}
			},
			
		],
	},
	{
		id: "ya30000",
		name: "ya30000",
		end: -30000,
		offset: 20,
		height: 39940,
		start: -30000,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb30000",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya30000",
				scrollArrows: {
					expl: false,
					top: "ya21000",
					bottom: "ya34000ya32000"
				}
			},

		],
	},
	{
		id: "ya34000ya32000",
		name: "ya34000ya32000",
		end: -32000,
		offset: 20,
		height: 157940,
		start: -34000,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb32000",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya34000ya32000",
				scrollArrows: {
					expl: false,
					top: "ya30000",
					bottom: "ya40000"
				}
			},
			
		]
	},
	{
		id: "ya40000",
		name: "ya40000",
		end: -40000,
		height: 1000,
		start: -40000,
		color: "#e395c3",
		topMsg: {
			color: "#e395c3",
			year: "labelb40000",
			desc: "blank",
		},
		content: [
			{
				type: "card",
				desc: "ya40000",
				scrollArrows: {
					expl: false,
					top: "ya34000ya32000"
				}
			},
			
		],
	},






















































/************************************* */


	// {
	// 	id: "classicalMiddleAgesInside",
	// 	name: "timelineClassicalMiddleAges",
	// 	end: 1200,
	// 	start: 1000,
	// 	color: "#91AFB1",
	// 	topMsg: {
	// 		color: "#91AFB1",
	// 		year: 1200,
	// 		desc: "classicalMiddleAgesStart",
	// 	},
	// 	content: [
	// 		{
	// 			type: "card",
	// 			desc: "classicalMiddleAgesExpl",
	// 			scrollArrows: {
	// 				expl: false,
	// 				top: "antiquity",
	// 				bottom: "timeline-end"
	// 			}
	// 		},
	// 	],
	// 	bottomMsg: {
	// 		color: "#91AFB1",
	// 		year: 1000,
	// 		desc: "classicalMiddleAgesEnd",
	// 	},
	// },
	// {
	// 	id: "antiquityInside",
	// 	name: "timelineAntiquity",
	// 	end: 476,
	// 	start: 350,
	// 	color: "#ED945F",
	// 	topMsg: {
	// 		color: "#92C6A4",
	// 		year: 476,
	// 		desc: "romanFall",
	// 	},
	// 	content: [
	// 		{
	// 			type: "card",
	// 			desc: "basEmpireExpl",
	// 		},
	// 	],
	// 	bottomMsg: {
	// 		color: "#ED945F",
	// 		year: 350,
	// 		desc: "startPeriod",
	// 	},
	// },
	// {
	// 	id: "neolithicInside",
	// 	name: "timelineNeolithic",
	// 	end: -2500,
	// 	start: -7000,
	// 	color: "#C4BC95",
	// 	topMsg: {
	// 		color: "#C4BC95",
	// 		year: "labelb2500,
	// 		desc: "endPeriod",
	// 	},
	// 	content: [
	// 		{
	// 			type: "card",
	// 			desc: "neolithicDuration",
	// 			scrollArrows: {
	// 				expl: false,
	// 				top: "antiquity",
	// 				bottom: "paleolithic"
	// 			}
	// 		},
	// 	],
	// 	bottomMsg: {
	// 		color: "#C4BC95",
	// 		year: "labelb7000,
	// 		desc: "startPeriod",
	// 	},
	// },
	// {
	// 	id: "magdalenianInside",
	// 	name: "timelineMagdalenian",
	// 	end: -12000,
	// 	start: -7000,
	// 	color: "#214A72",
	// 	topMsg: {
	// 		color: "#214A72",
	// 		year: "labelb2500,
	// 		desc: "endPeriod",
	// 	},
	// 	content: [
	// 		{
	// 			type: "card",
	// 			desc: "magdalenianDuration",
	// 			scrollArrows: {
	// 				expl: false,
	// 				top: "paleolithic",
	// 				bottom: "timeline-end"
	// 			}
	// 		},
	// 	],
	// 	bottomMsg: {
	// 		color: "#214A72",
	// 		year: "labelb7000,
	// 		desc: "startPeriod",
	// 	},
	// },
];
