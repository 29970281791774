import React, { useEffect, useState } from "react";
import { GetTrad } from "../Partials/GetTrad";
import "./credits.css";
import { useDispatch, useSelector } from "react-redux";
import { resetProgress } from "src/actions/game";
import { IRootState } from "src/reducers";
import { useAuthorizations } from "src/hooks/useAuthorizations";
import { trads } from "src/data/trads";
import { setLanguage } from "src/actions/app";

export const Credits = (props: {
	language: string;
	displayCreditsPage: any;
}) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const dispatch = useDispatch();

	const { askAuthorization, denyAuthorization, geoLoc } = useAuthorizations();

	const [langSelection, setLangSelection] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			let languagePopupElem = document.getElementById("languagePopup");

			if (languagePopupElem) {
				if (langSelection) {
					languagePopupElem.style.display = "flex";
				} else {
					languagePopupElem.style.display = "none";
				}
			}
		}, 10);
	}, [langSelection]);

	return (
		<div className='credits' onClick={(e) => {
			e.stopPropagation();
			setLangSelection(false);
		}}>
			<div className='credits-content'>
				<div
					className='ui-top-button ui-top-button-back'
					style={{ position: "absolute", zIndex: "300", marginTop: "64px" }}
					onClick={() => props.displayCreditsPage(false)}
				></div>
				<div
					className='credits-lang'
					style={{ position: "absolute", zIndex: "300", marginTop: "64px" }}
					onClick={() => { }}
				>
					<div className='credits-langSelectText' onClick={(e) => {
						e.stopPropagation();
						if (!langSelection) setLangSelection(true);
					}}>
						<div className='credits-langSelectFlag' style={{ backgroundImage: trads.find((t: any) => t.id === props.language)?.flag }}></div>
						<GetTrad value="lang" lang={props.language} />
						<div className='credits-langSelectArrow'></div>
					</div>

					{langSelection && (
						<div
							id='languagePopup'
							className='languagePopup'
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							{trads.map((t: any) => {
								return (
									<div
										className={
											"splashscreenPopupButton " +
											(t.id === language ? "splashscreenPopupButtonSelected" : "")
										}
										onClick={(e) => {
											e.stopPropagation();
											dispatch(setLanguage(t.id));
											setLangSelection(false);
										}}
									>
										<div
											className={
												"splashscreenPopupButtonText " +
												(t.id === language ? "splashscreenPopupButtonTextSelected" : "")
											}
										>
											<div
												className='splashscreen-start-langSelectFlag'
												style={{ backgroundImage: t.flag }}
											></div>
											<GetTrad lang={t.id} value={"lang"} />
										</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
				<div className='credits-title'>
					<GetTrad lang={language} value='creditsTitle' />
				</div>
				<div className='credits-bold'>
					<GetTrad lang={props.language} value={"immersiveGame"} />
				</div>
				<div className='credits-regular'>
					<GetTrad lang={props.language} value={"manyMarquage"} />
				</div>

				<div className="credits-geoloc">
					<div className="credits-switch">
						{geoLoc && <div className="credits-switchOutside" onClick={() => {
							denyAuthorization();
						}}>
							<div className="credits-switchInside"></div>
						</div>}
						{!geoLoc && <div className="credits-switchOutsideDeny" onClick={() => {
							askAuthorization();
						}}>
							<div className="credits-switchInsideDeny"></div>
						</div>}
					</div>
					<div className="credits-geoloc-text">
						<div className="credits-geoloc-textBold"><GetTrad lang={props.language} value={"playingInPlace"} /></div>
						<div className="credits-geoloc-textRegular"><GetTrad lang={props.language} value={"cutGeoloc"} /></div>
					</div>
				</div>

				<div
					className='resetButton'
					onClick={() => {
						dispatch(resetProgress())
						props.displayCreditsPage(false)
					}}
				>
					<GetTrad lang={props.language} value={"resetExp"} />
				</div>

				<div className='credits-subtitle'>
					<GetTrad lang={language} value='credits' />
				</div>
				<div className='credits-bold'>
					<GetTrad lang={props.language} value={"timescopeProject"} />
				</div>
				<div className='credits-regular'>
					<GetTrad lang={props.language} value={"creditsContent"} />
				</div>
				<div className='credits-regular' style={{ marginTop: "6px" }}>
					<GetTrad lang={props.language} value={"creditsPhotos"} />
				</div>
				<div className='credits-regular' style={{ marginTop: "6px" }}>
					<GetTrad lang={props.language} value={"creditsTexts"} />
				</div>
				<div className='credits-regular' style={{ marginTop: "6px" }}>
					<GetTrad lang={props.language} value={"creditsDesign"} />
				</div>
				<div style={{ height: "64px", width: "100%" }}></div>
			</div>
		</div>
	);
};
