// Components
import { subtimeline, timeline } from "src/data/timeline";
import { GetTrad } from "../Partials/GetTrad";
// Style
import "./timeline.css";
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
import React, { useEffect, useState } from "react";
import { trads } from "src/data/trads";

const PIXELBYYEAR = 20;

function generateMilleniums(last: number, first: number) {
	let array = [];
	for (let i = last; last > first; last = last - 1000) {
		array.push("");
	}
	return <div style={{ position: "absolute", height: "100%", width: "100%" }}>{
		array.map((i: any, index: any) => {
			return <div
				className='timeline-millenniums'
				style={{
					top: ((new Date().getFullYear() - 2000) * PIXELBYYEAR + (index * PIXELBYYEAR * 100)) + "px",
					height: PIXELBYYEAR * 100 + "px",
					width: "100%"
				}}
			></div>
		})}</div>
}

function generateCenturies(last: number, first: number) {
	let array = [];
	for (let i = last; last > first; last = last - 100) {
		array.push("");
	}
	return <div style={{ position: "absolute", height: "100%", width: "100%" }}>{
		array.map((i: any, index: any) => {
			return <div
				className='timeline-centuries'
				style={{
					top: ((new Date().getFullYear() % 10) * PIXELBYYEAR + (index * PIXELBYYEAR * 10)) + "px",
					height: PIXELBYYEAR * 10 + "px",
					backgroundSize: "200%",
					width: "40%"
				}}
			></div>
		})}</div>
}

function generateDecades(last: number, first: number) {
	let array = [];
	for (let i = last; last > first; last = last - 100) {
		array.push("");
	}
	return <div style={{ position: "absolute", height: "100%", width: "100%" }}>{
		array.map((i: any, index: any) => {
			return <div
				className='timeline-decades'
				style={{
					top: ((new Date().getFullYear()) * PIXELBYYEAR + (index * PIXELBYYEAR)) + "px",
					height: PIXELBYYEAR + "px",
					backgroundSize: "200%",
					width: "20%"
				}}
			></div>
		})}</div>
}

export const Timeline = () => {
	const { language } = useSelector((state: IRootState) => state.app);

	let dateWrote = new Date().getFullYear() - (new Date().getFullYear() % 100);

	useEffect(() => {
		for (let timelineElem of subtimeline) {
			if (timelineElem.topMsg) {
				let yearElem = document.getElementById("st-y-" + timelineElem.topMsg.desc);
				let descElem = document.getElementById("st-d-" + timelineElem.topMsg.desc);

				if (yearElem && descElem) {
					console.log("passage");
					descElem.style.left = 30 + yearElem.getBoundingClientRect().width + "px";
					descElem.style.maxWidth = "calc(100dvw - " + (84 + yearElem.getBoundingClientRect().width) + "px)"
				}
			}
		}

		for (let timelineElem of timeline) {
			if (timelineElem.topMsg) {
				let yearElem = document.getElementById("st-y-" + timelineElem.topMsg.desc);
				let descElem = document.getElementById("st-d-" + timelineElem.topMsg.desc);

				if (yearElem && descElem) {
					console.log("passage");
					descElem.style.left = 30 + yearElem.getBoundingClientRect().width + "px";
					descElem.style.maxWidth = "calc(100dvw - " + (84 + yearElem.getBoundingClientRect().width) + "px)"
				}
			}
		}

	})

	const allTrads = trads.find((t: any) => t.id === language);

	const [desc, showDesc] = useState<string | null>(null);

	return (
		<div className='timeline'>
			{desc && <div className="timeline-modalContainer" onClick={() => showDesc(null)}>
				<div className='map-popupCenter' onClick={() => showDesc(null)}>
					<div className='map-popupCenter-text' style={{ fontSize: "14px" }}>
						<GetTrad value={desc} lang={language} />
					</div>
					<div
						className='map-popupCenter-button'
						onClick={() => showDesc(null)}
					>
						<GetTrad value='ok' lang={language} />
					</div>
				</div>
			</div>}
			<div className='timeline-header'>
				<div className='timeline-title'>
					<GetTrad lang={language} value='timelineTitle' />
				</div>
				<div className='timeline-subtitle'>
					<GetTrad lang={language} value='explanationTimeline' />
				</div>
			</div>
			<div className='timeline-content'>
				<div
					className='timeline-body'
					style={{
						position: "absolute",
						height:
							Math.abs(
								timeline.sort((t1: any, t2: any) => t1.start - t2.start)[0]
									.start
							) * PIXELBYYEAR +
							new Date().getFullYear() +
							360 +
							"px",
					}}
				>
					<div className='timeline-years'>
						{generateMilleniums(new Date().getFullYear(), timeline.sort((t1: any, t2: any) => t1.start - t2.start)[0].start)}
						{generateCenturies(new Date().getFullYear(), timeline.sort((t1: any, t2: any) => t1.start - t2.start)[0].start)}
						{generateDecades(new Date().getFullYear(), timeline.sort((t1: any, t2: any) => t1.start - t2.start)[0].start)}

						{[
							dateWrote,
							dateWrote - 100,
							dateWrote - 200,
							dateWrote - 300,
							dateWrote - 400,
							dateWrote - 500,
						].map((date: number, index: number) => (
							<div
								className='timeline-yearName'
								style={{
									top: (new Date().getFullYear() - 2000) * PIXELBYYEAR + index * PIXELBYYEAR * 100 + "px",
									opacity: 1 - 0.2 * index,
								}}
							>
								{date}
							</div>
						))}
					</div>

					{timeline.map((t: any, i: number) => (
						<div
							id={t.id}
							className='timeline-period'
							style={{
								zIndex: 300 - i,
								top: ((new Date().getFullYear() - (t.end)) * PIXELBYYEAR) + 16 + "px",
								height: Math.abs(t.start - t.end) * PIXELBYYEAR + "px",
								margin: "0 0 0 0",
								// (i === (timeline.length - 1) ? 16 : 0)
							}}
						>
							{t.topMsg && (
								<div className='timeline-period-top'>
									{t.topMsg.desc && (
										<React.Fragment>
											<div
												className='timeline-period-top-delimiter1'
												style={{ backgroundColor: t.topMsg.color }}
											></div>
											<div style={{ margin: (t.offset ? t.offset + 8 : 8) + "px 0 0 8px" }} className='timeline-period-top-text'>
												<div className='timeline-period-top-textContent'>
													<div id={"st-y-" + t.topMsg.desc} className='timeline-period-top-textContent-year'>
														<GetTrad lang={language} value={t.topMsg.year} />
													</div>
													<div
														id={"st-d-" + t.topMsg.desc}
														className='timeline-period-top-textContent-desc'
														style={{ color: t.topMsg.color }}
													>
														<GetTrad lang={language} value={t.topMsg.desc} />
													</div>
												</div>
												<div
													className='timeline-period-top-delimiter2'
													style={{ backgroundColor: t.topMsg.color }}
												></div>
											</div>
										</React.Fragment>
									)}
									{!t.topMsg.desc && (
										<div
											className='timeline-period-top-delimiter3'
											style={{ backgroundColor: t.topMsg.color }}
										></div>
									)}
								</div>
							)}
							{t.content &&
								t.content.map((tc: any) => {
									switch (tc.type) {
										case "card":
											return (
												<div
													className='timeline-cardName'
													style={{
														margin: t.offset
															? 16 + t.offset + "px 16px 16px 32px"
															: "16px 16px 16px 32px",
													}}
												>
													{tc.desc && (
														<GetTrad lang={language} value={tc.desc} />
													)}
													{/* {tc.scrollArrows?.expl && (
														<div className='timeline-scrollArrows'>
															<GetTrad
																lang={language}
																value={"scrollArrowsExpl"}
															/>
														</div>
													)}
													{tc.scrollArrows && (
														<div className='timeline-scrollArrows'>
															<div
																className='timeline-scrollArrow'
																onClick={() => {
																	let elem = document.getElementById(
																		tc.scrollArrows.top
																	);

																	if (elem) {
																		let target = elem.style.top
																			.split(" ")[0]
																			.slice(
																				0,
																				elem.style.top.split(" ")[0].length - 2
																			);

																		let elems =
																			document.getElementsByClassName(
																				"timeline-content"
																			);

																		for (let elem of elems) {
																			elem.scrollTo(0, parseInt(target) + 280 + (tc.scrollArrows.topOffset ? tc.scrollArrows.topOffset : 0));
																		}
																	}
																}}
															>
																<div className='timeline-scrollArrowTop'></div>
															</div>
															<div
																className='timeline-scrollArrow'
																onClick={() => {
																	let elem = document.getElementById(
																		tc.scrollArrows.bottom
																	);

																	if (elem) {
																		let target = elem.style.top.slice(
																			0,
																			elem.style.top.split(" ")[0].length - 2
																		);

																		console.log("target", target);

																		let elems =
																			document.getElementsByClassName(
																				"timeline-content"
																			);

																		for (let elem of elems) {
																			elem.scrollTo(0, parseInt(target) + 280 + (tc.scrollArrows.bottomOffset ? tc.scrollArrows.bottomOffset : 0));
																		}
																	}
																}}
															>
																<div className='timeline-scrollArrowBottom'></div>
															</div>
														</div>
													)} */}
												</div>
											);
										default:
											return <div className='timeline-cardName'></div>;
									}
								})}
							{t.bottomMsg && (
								<div className='timeline-period-bottom'>
									{t.bottomMsg.desc && (
										<React.Fragment>
											<div
												className='timeline-period-top-delimiter1'
												style={{ backgroundColor: t.bottomMsg.color }}
											></div>
											<div className='timeline-period-top-text'>
												<div className='timeline-period-top-textContent'>
													<div className='timeline-period-top-textContent-year'>
														<GetTrad lang={language} value={t.bottomMsg.year} />

													</div>
													<div
														className='timeline-period-top-textContent-desc'
														style={{ color: t.bottomMsg.color }}
													>
														<GetTrad lang={language} value={t.bottomMsg.desc} />
													</div>
												</div>
												<div
													className='timeline-period-top-delimiter2'
													style={{ backgroundColor: t.bottomMsg.color }}
												></div>
											</div>
										</React.Fragment>
									)}
									{!t.bottomMsg.desc && (
										<div
											className='timeline-period-top-delimiter3'
											style={{ backgroundColor: t.bottomMsg.color }}
										></div>
									)}
								</div>
							)}

							<div
								className='timeline-cardName-periodColor'
								style={{ backgroundColor: t.color }}
							>
								<div className='timeline-cardName-periodColorName'>
									<GetTrad lang={language} value={t.name} /> {/* { "(" + t.start + ' - ' + t.end + ")"} */}
								</div>
							</div>
						</div>
					))}
					<div
						id={"timeline-end"}
						className='timeline-period'
						style={{
							zIndex: 0,
							top:
								new Date().getFullYear() +
								Math.abs(
									timeline.sort((t1: any, t2: any) => t1.start - t2.start)[0]
										.start
								) +
								16 +
								"px",
							height: "0px",
							margin: "0 0 0 0",
						}}
					></div>
					{subtimeline.map((t: any, i: number) => (
						<div
							id={t.id}
							className='subtimeline-period'
							style={{
								zIndex: 500 - i,
								top: (new Date().getFullYear() - t.end) * PIXELBYYEAR - 4 + "px",
								height: Math.abs(t.start - t.end) + 32 + 16 + t.offset + (t.height ? t.height : 0) + "px",
								margin: "0 0 0 0",
							}}
						>
							{t.topMsg && (
								<div className='subtimeline-period-top'>
									{t.topMsg.desc && (
										<React.Fragment>
											<div
												className='subtimeline-period-top-delimiter1'
												style={{ backgroundColor: t.topMsg.color }}
											></div>
											<div style={{ margin: t.offsetTitle + "px 0 0 8px" }} className='subtimeline-period-top-text'>
												<div className='subtimeline-period-top-textContent'>
													<div id={"st-y-" + t.topMsg.desc} className='subtimeline-period-top-textContent-year'>
														<GetTrad lang={language} value={t.topMsg.year} />
													</div>
													<div
														id={"st-d-" + t.topMsg.desc}
														className='subtimeline-period-top-textContent-desc'
														style={{ color: t.topMsg.color }}
													>
														<GetTrad lang={language} value={t.topMsg.desc} />
													</div>
												</div>
												<div
													className='subtimeline-period-top-delimiter2'
													style={{ backgroundColor: t.topMsg.color }}
												></div>
											</div>
										</React.Fragment>
									)}
									{!t.topMsg.desc && (
										<div
											className='subtimeline-period-top-delimiter3'
											style={{ backgroundColor: t.topMsg.color }}
										></div>
									)}
								</div>
							)}
							{t.content &&
								t.content.map((tc: any) => {
									switch (tc.type) {
										case "card":
											return (
												<div style={{ margin: (t.offset ? t.offset : 46) + "px 16px 48px 32px" }} className='subtimeline-cardName'>
													{tc.desc && !tc.crop && (
														<GetTrad lang={language} value={tc.desc} />
													)}
													{tc.desc && tc.crop &&
														<span><span>{(allTrads as any)[tc.desc]?.substring(0, tc.crop) + "[...] "}</span>
															<span style={{ color: t.color }} onClick={() => {
																showDesc(tc.desc);
															}}><GetTrad value="showMoreButton" lang={language} /></span></span>
													}
													<div className="timeline-scrollArrowsContainer">
														{tc.scrollArrows?.expl && (
															<div className='timeline-scrollArrows'>
																<GetTrad
																	lang={language}
																	value={"scrollArrowsExpl"}
																/>
															</div>
														)}
														{tc.scrollArrows && (
															<div className='timeline-scrollArrows'>
																<div
																	className='timeline-scrollArrow'
																	onClick={() => {
																		let elem = document.getElementById(
																			tc.scrollArrows.top
																		);

																		if (elem) {
																			let target = elem.style.top
																				.split(" ")[0]
																				.slice(
																					0,
																					elem.style.top.split(" ")[0].length - 2
																				);

																			let elems =
																				document.getElementsByClassName(
																					"timeline-content"
																				);

																			for (let elem of elems) {
																				elem.scrollTo(0, parseInt(target) + 280 + (tc.scrollArrows.topOffset ? tc.scrollArrows.topOffset : 0));
																			}
																		}
																	}}
																>
																	<div className='timeline-scrollArrowTop'></div>
																</div>
																{tc.scrollArrows.bottom && (<div
																	className='timeline-scrollArrow'
																	onClick={() => {
																		let elem = document.getElementById(
																			tc.scrollArrows.bottom
																		);

																		if (elem) {
																			let target = elem.style.top.slice(
																				0,
																				elem.style.top.split(" ")[0].length - 2
																			);

																			console.log("target", target);

																			let elems =
																				document.getElementsByClassName(
																					"timeline-content"
																				);

																			for (let elem of elems) {
																				console.log("=>", tc.scrollArrows)
																				elem.scrollTo(0, parseInt(target) + 280 + (tc.scrollArrows.bottomOffset ? tc.scrollArrows.bottomOffset : 0));
																			}
																		}
																	}}
																>
																	<div className='timeline-scrollArrowBottom'></div>
																</div>)}
															</div>
														)}
													</div>
												</div>
											);
										default:
											return <div className='subtimeline-cardName'>Test</div>;
									}
								})}
							{t.bottomMsg && (
								<div className='subtimeline-period-bottom'>
									{t.bottomMsg.desc && (
										<React.Fragment>
											<div
												className='subtimeline-period-top-delimiter1'
												style={{ backgroundColor: t.bottomMsg.color }}
											></div>
											<div className='subtimeline-period-top-text'>
												<div className='subtimeline-period-top-textContent'>
													<div className='subtimeline-period-top-textContent-year'>
														<GetTrad lang={language} value={t.bottomMsg.year} />
													</div>
													<div
														className='subtimeline-period-top-textContent-desc'
														style={{ color: t.bottomMsg.color }}
													>
														<GetTrad lang={language} value={t.bottomMsg.desc} />
													</div>
												</div>
												<div
													className='subtimeline-period-top-delimiter2'
													style={{ backgroundColor: t.bottomMsg.color }}
												></div>
											</div>
										</React.Fragment>
									)}
									{!t.bottomMsg.desc && (
										<div
											className='subtimeline-period-top-delimiter3'
											style={{ backgroundColor: t.bottomMsg.color }}
										></div>
									)}
								</div>
							)}

							{t.displayBorder && <div
								className='subtimeline-cardName-periodColor'
								style={{ backgroundColor: t.color }}
							>
								<div className='subtimeline-cardName-periodColorName'>
									<GetTrad lang={language} value={t.name} />
								</div>
							</div>}
						</div>
					))}
					<div className='timeline-bottomPage'>
						<div className='timeline-bottomPageText'>
							<GetTrad lang={language} value='timelineBottom' />
						</div>
						<div
							className='timeline-bottomPageButton'
							onClick={() => {
								let elems = document.getElementsByClassName("timeline-content");

								for (let elem of elems) {
									elem.scrollTo(0, 0);
								}
							}}
						>
							<div className='timeline-bottomPageButtonText'>
								<GetTrad lang={language} value='timelineBackFutur' />
								<div className='timeline-bottomPageButtonArrow'></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
